import React from 'react';
import { Link, graphql } from 'gatsby';
import BoxRows from 'components/box/box-rows/box-rows';
import Img from 'gatsby-image';
import Carousel from 'components/carousel/carousel';
import { FullWidthImg } from 'components/image-wrappers';
import Title from 'components/title';
import Layout from 'components/layout';
import Modal from 'containers/modal';
import Stack from 'components/stack';
import StackItem from 'components/stack/stack-item';
import {
  TextWrapper,
  TextUnderline,
  Body,
  Description,
  StickyText,
  ProjectBox,
  ProjectInfo,
  BackgroundText,
  NavigationWrapper,
  NavButtons,
  NavSubText,
  ImgTitle,
  ImgWrapper,
  HeroImgContainer,
  BodyImgWrapper,
  UsabilityImgWrapper
} from 'components/GlobalComponents';

const Ceramics = ({ data }) => {
  const project = data.ceramicsJson;

  const [next, nextTitle] = [
    '/the-whistling-cook',
    'The Whistling Cook Design & Development',
  ];

  return (
    <Layout>
      <HeroImgContainer>
        <FullWidthImg
          key={project.gallery.hero.id}
          opacity={0.1}
          fluid={project.gallery.hero.childImageSharp.fluid}
        />
        <ImgTitle>{project.title}</ImgTitle>
      </HeroImgContainer>

      <TextWrapper>
        <BoxRows>
          <Stack>
            <StackItem>
              <Title as="h4">ROLE</Title>
              <BackgroundText>{project.role}</BackgroundText>
            </StackItem>
            <StackItem>
              <Title as="h4">CONTEXT</Title>
              <BackgroundText>{project.context}</BackgroundText>
            </StackItem>
            <StackItem>
              <Title as="h4">YEAR</Title>
              <BackgroundText>{project.year}</BackgroundText>
            </StackItem>
            <StackItem>
              <Title as="h4">METHODS</Title>
              <BackgroundText
                dangerouslySetInnerHTML={{
                  __html: project.methods.childMarkdownRemark.html,
                }}
              ></BackgroundText>
            </StackItem>
          </Stack>
          <ProjectInfo>
            <Title as="h3">Overview</Title>
            <TextUnderline />

            <Description
              dangerouslySetInnerHTML={{
                __html: project.overview.childMarkdownRemark.html,
              }}
            />
            <Title as="h4">Goals:</Title>
            <TextUnderline />

            <Description
              dangerouslySetInnerHTML={{
                __html: project.goals.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </BoxRows>

        <ProjectBox>
          <Title as="h3">Competitive Audit</Title>
          <TextUnderline />
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.literature.childMarkdownRemark.html,
              }}
            />
            <Description
              dangerouslySetInnerHTML={{
                __html: project.competitiveAudit.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
          <>
            <BodyImgWrapper>
              <Img
                fluid={
                  project.gallery.competitiveAuditLogos.childImageSharp.fluid
                }
              />
            </BodyImgWrapper>
          </>
        </ProjectBox>

        <ProjectBox>
          <Title as="h3">{project.bodyHeader}</Title>
          <TextUnderline />
          <Body>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.interviews.childMarkdownRemark.html,
              }}
            ></Description>
          </Body>
        </ProjectBox>

        <BoxRows>
          <StickyText height={290}>
            <Title as="h3">Meet the Users</Title>
            <TextUnderline />
            <Body>
              <Description
                dangerouslySetInnerHTML={{
                  __html: project.personaNarrative1.childMarkdownRemark.html,
                }}
              />
            </Body>
          </StickyText>
          <>
            <ImgWrapper>
              <Modal
                key={project.gallery.persona1.id}
                image={project.gallery.persona1.childImageSharp.fluid}
              >
                <Img fluid={project.gallery.persona1.childImageSharp.fluid} />
              </Modal>
            </ImgWrapper>
          </>
        </BoxRows>

        <BoxRows>
          <StickyText height={230}>
            <Body>
              <Description
                dangerouslySetInnerHTML={{
                  __html: project.personaNarrative2.childMarkdownRemark.html,
                }}
              />
            </Body>
          </StickyText>
          <>
            <ImgWrapper>
              <Modal
                key={project.gallery.persona2.id}
                image={project.gallery.persona2.childImageSharp.fluid}
              >
                <Img fluid={project.gallery.persona2.childImageSharp.fluid} />
              </Modal>
            </ImgWrapper>
          </>
        </BoxRows>

        <ProjectBox>
          <Title as="h3">Wireframes</Title>
          <TextUnderline />
          <BodyImgWrapper>
            <Carousel images={project.wireframes} />
          </BodyImgWrapper>
        </ProjectBox>

        <ProjectBox>
          <Title as="h3">Usability Test: Assessment Phase</Title>
          <TextUnderline />

          <UsabilityImgWrapper>
            <Img
              fluid={
                project.gallery.featurePrioritization.childImageSharp.fluid
              }
            />
          </UsabilityImgWrapper>

          <ProjectInfo>
            <Description>
              To determine which feature to test, I made a list of features and
              rated how important this feature is to the success of the product.
              Then, I rated each feature based on how confident I am of its
              design. The two variables are:
              <h5>IMPORTANCE x WORK = TOTAL</h5>
              <ul>
                <li>
                  Importance - how important is the task to the user? (1 = low,
                  5 = critical)
                </li>
                <li>
                  Need Work - how much work is needed to refine this feature (1
                  = low, 5 = critical)
                </li>
              </ul>
            </Description>
          </ProjectInfo>
        </ProjectBox>

        <BoxRows reverse>
          <StickyText height={510}>
            <Body>
              <p>
                After turning the digital wireframe to a low-fidelity prototype,
                I conducted a guerilla tests at the local ceramic store. In
                total, I gathered 6 participants within the targeted
                demographics for a moderated usability test, asking each to
                perform different scenarios on the prototype.
              </p>
              <Title as="h4" color="#C6B895">
                Test Objectives
              </Title>
              <Description>
                <ul>
                  <li>
                    To observe users direct and indirect path to completing the
                    task
                  </li>
                  <li>
                    To identify potential issues regarding browsing, checkout,
                    and payment process
                  </li>
                  <li>
                    To discover pain points and confusion when using the new
                    product
                  </li>
                </ul>
              </Description>
            </Body>
          </StickyText>
          <>
            <ImgWrapper>
              <Modal
                key={project.gallery.userInterview.id}
                image={project.gallery.userInterview.childImageSharp.fluid}
              >
                <Img
                  fluid={project.gallery.userInterview.childImageSharp.fluid}
                />
              </Modal>
            </ImgWrapper>
          </>
        </BoxRows>

        <ProjectBox>
          <Title as="h3">Key Insight</Title>
          <TextUnderline />

          <Body>
            <Description>
              <ul>
                <li>
                  <strong>Smarter design. </strong>While observing my testers, I
                  saw a palpable lack of excitment for the search feature. ⟶ I
                  replace a listed catagory with a pictoral category.
                </li>{"\n"}
                <li>
                  <strong>Remove unecessary complexity. </strong>A fair number
                  of testers initally perceived the stagger design as a glitch.
                  ⟶ I removed the staggered design with a more convential grid.
                </li>{"\n"}
                <li>
                  <strong>Reduce clutter. </strong>While browsing catalogue,
                  testers feel they wouldn&apos;t add a product to wish list
                  without first inspecting the product more closely. ⟶ I removed
                  &apos;adding to wish list&apos; feature in browsing step and
                  replaced it with color options/
                </li>
              </ul>
            </Description>
          </Body>
        </ProjectBox>

        <>
          <UsabilityImgWrapper>
            <Img fluid={project.gallery.usability2.childImageSharp.fluid} />
          </UsabilityImgWrapper>
        </>
        <>
          <UsabilityImgWrapper>
            <Img fluid={project.gallery.usability1.childImageSharp.fluid} />
          </UsabilityImgWrapper>
        </>

        <ProjectBox>
          <Title as="h3">Hi-Fidelity</Title>
          <TextUnderline />

          <BodyImgWrapper>
            <Carousel images={project.hifi} />
          </BodyImgWrapper>

          <BodyImgWrapper>
            <Img fluid={project.gallery.prototype.childImageSharp.fluid} />
          </BodyImgWrapper>
        </ProjectBox>

        <ProjectBox>
          <Title as="h3">Key Takeaway & Future Development</Title>
          <TextUnderline />
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.takeaway.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </ProjectBox>
      </TextWrapper>

      <NavigationWrapper background={'#f7d68b'}>
        <Link to={`${next}`}>
          <NavButtons line>
            <NavSubText>Next &#8594;</NavSubText>
            <Title as="span">{nextTitle}</Title>
          </NavButtons>
        </Link>
      </NavigationWrapper>
    </Layout>
  );
};

export const query = graphql`
  query CeramicQuery {
    ceramicsJson {
      title
      year
      role
      context
      methods {
        childMarkdownRemark {
          html
        }
      }
      overview {
        childMarkdownRemark {
          html
        }
      }
      goals {
        childMarkdownRemark {
          html
        }
      }
      literature {
        childMarkdownRemark {
          html
        }
      }
      competitiveAudit {
        childMarkdownRemark {
          html
        }
      }
      interviews {
        childMarkdownRemark {
          html
        }
      }
      personaNarrative1 {
        childMarkdownRemark {
          html
        }
      }
      personaNarrative2 {
        childMarkdownRemark {
          html
        }
      }
      takeaway {
        childMarkdownRemark {
          html
        }
      }
      bodyHeader
      body
      gallery {
        hero {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        competitiveAuditLogos {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        userInterview {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        persona1 {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        persona2 {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        featurePrioritization {
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        usability1 {
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        usability2 {
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        prototype {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      wireframes {
        image1 {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image4 {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image5 {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image6 {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      hifi {
        image1 {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image4 {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image5 {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image6 {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default Ceramics;
