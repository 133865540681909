import React, { useState } from 'react';
import ItemsCarousel from 'react-items-carousel';
import Img from 'gatsby-image';

const Carousel = ({ images }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 1;

  return (
    <div style={{ padding: `${chevronWidth}em` }}>
      <ItemsCarousel
        infiniteLoop={true}
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={3}
        gutter={20}
        leftChevron={<button>{'<'}</button>}
        rightChevron={<button>{'>'}</button>}
        outsideChevron
        chevronWidth={chevronWidth}
      >
        {Object.values(images).map((image) => (
          <Img key={image.id} fluid={image.childImageSharp.fluid} />
        ))}
      </ItemsCarousel>
    </div>
  );
};

export default Carousel;
